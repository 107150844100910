import globalConfig from '~/configs/global';
import { ApiMethodTypes } from '~/types/api';

export async function callBackend<T, U>(
    method: ApiMethodTypes,
    urlPath: string,
    body?: T,
    extraHeaders?: Record<string, string> | undefined,
    errorHandlers?: Record<string, (response?: Response) => Promise<void>>,
): Promise<U> {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Client-version': globalConfig.clientVersion,
        ...extraHeaders,
    };

    if (typeof navigator !== 'undefined' && !navigator.onLine && errorHandlers?.offline) {
        errorHandlers.offline();
    }

    let bodyJson: string | undefined;

    if (body) {
        bodyJson = JSON.stringify(body);
    }

    const response = await fetch(`${urlPath}`, {
        method,
        body: bodyJson,
        headers,
    });

    if (!response.ok) {
        if (errorHandlers && errorHandlers[response.status]) {
            await errorHandlers[response.status](response);
        } else {
            throw new Error(`Error ${response.status}: ${response.statusText}`);
        }
    }

    // may error if there is no body, return empty array
    return response.json().catch(() => ({}));
}
